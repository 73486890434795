import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const mock = [
  "/1-8.1.png",
  "/1-8.2.png",
  "/1-8.3.png",
  "/1-8.4.png",
  "/1-8.5.png",
  "/1-8.6.png",
];

const GridItemPartnersBlock = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexWrap="wrap" justifyContent={"center"} width={1}>
      {mock.map((item, i) => (
        <Box maxWidth={80} width={1} marginTop={2} marginRight={4} key={i}>
          <Box
            component="img"
            height={1}
            width={1}
            src={item}
            alt="..."
            sx={{
              filter:
                theme.palette.mode === "dark"
                  ? "brightness(0) invert(0.7)"
                  : "contrast(0) brightness(0)",
              objectFit: "contain",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

const RelatedCorpsLogoGrid = (): JSX.Element => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            align={"center"}
            variant={"h4"}
            fontWeight={700}
          >
            실적사 현황
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
          >
            <GridItemPartnersBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RelatedCorpsLogoGrid;
