import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const data = [
  {
    number: 1,
    title: "개요",
    author: "최용우",
    date: "2021-08-01",
  },
  {
    number: 2,
    title: "회사소개",
    author: "최용우",
    date: "2021-08-01",
  },
];

const CompanyAttachmentsView = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: "repeat-x",
            position: "relative",
          }}
        >
          <Container>
            <Typography variant={"h2"}>자료실</Typography>
          </Container>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>번호</TableCell>
                      <TableCell>제목</TableCell>
                      <TableCell>작성자</TableCell>
                      <TableCell>작성일</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow>
                        <TableCell>{row.number}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.author}</TableCell>
                        <TableCell>{row.date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: "100%",
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default CompanyAttachmentsView;
