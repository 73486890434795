import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { ItemType, PopupBox } from "./components";

const mock = [
  {
    image: "/blog1.png",
    description: "부산 서구 아미동 LH 입주청소",
    title: "입주·준공청소",
    link: "https://blog.naver.com/chd5567/222960026011",
  },
  {
    image: "/blog2.png",
    description: "부산 광안리 광안목 특수청소 (기름때청소)",
    title: "특수청소",
    link: "https://blog.naver.com/chd5567/222952997343",
  },
  {
    image: "/blog2.png",
    description: "부산 수영구 비콘그라운드 특수청소",
    title: "특수청소",
    link: "https://blog.naver.com/chd5567/223002325191",
  },
];
// const mock = [
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img8.jpg",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     title: "시공내역1",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img9.jpg",
//     description: "Excepteur sint occaecat cupidatat non proident",
//     title: "시공내역2",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img12.jpg",
//     description: "Eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     title: "시공내역3",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img11.jpg",
//     description:
//       "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
//     title: "시공내역4",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img11.jpg",
//     description: "At vero eos et accusamus et iusto odio dignissimos ducimus",
//     title: "시공내역5",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img12.jpg",
//     description:
//       "Qui blanditiis praesentium voluptatum deleniti atque corrupti",
//     title: "시공내역6",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img9.jpg",
//     description:
//       "On the other hand, we denounce with righteous indignation and dislike",
//     title: "시공내역7",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img8.jpg",
//     description: "Quos dolores et quas molestias excepturi",
//     title: "시공내역8",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img12.jpg",
//     description: "Et harum quidem rerum facilis est et expedita distinctio",
//     title: "시공내역9",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img11.jpg",
//     description: "Nam libero tempore, cum soluta nobis est eligendi optio",
//     title: "시공내역10",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img8.jpg",
//     description: "Itaque earum rerum hic tenetur a sapiente delectus",
//     title: "시공내역11",
//     link: "https://blog.naver.com",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img9.jpg",
//     description:
//       "On the other hand, we denounce with righteous indignation and dislike",
//     title: "시공내역12",
//     link: "https://blog.naver.com",
//   },
// ];

const ImageCard = ({ item }: { item: ItemType }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    //setOpen(true)
    window.open(item.link, "_blank");
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        component={Button}
        display={"block"}
        width={1}
        height={1}
        sx={{
          textDecoration: "none",
          transition: "all .2s ease-in-out",
          "&:hover": {
            transform: `translateY(-${theme.spacing(1 / 2)})`,
          },
        }}
        onClick={onClick}
      >
        <Box
          component={Card}
          width={1}
          height={1}
          display={"flex"}
          flexDirection={"column"}
        >
          <CardMedia
            image={item.image}
            title={item.title}
            sx={{
              height: { xs: 340, md: 400 },
              filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            }}
          />
          <Box component={CardContent}>
            <Typography variant={"h6"} fontWeight={700} gutterBottom>
              {item.title}
            </Typography>
            <Typography variant={"body2"} color="text.secondary">
              {item.description}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box component={CardActions} justifyContent={"flex-start"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"4px"}
              padding={"1rem"}
              color={"#377dff"}
            >
              Learn more
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </Box>
          </Box>
        </Box>
      </Box>
      <PopupBox onClose={() => setOpen(false)} open={open} item={item} />
    </Grid>
  );
};

const Main = (): JSX.Element => {
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <ImageCard key={i} item={item} />
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
