import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const CompanyOverviewView = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: "repeat-x",
            position: "relative",
          }}
        >
          <Container>
            <Typography variant={"h2"}>개요</Typography>
          </Container>
          <Container>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>회사명</TableCell>
                    <TableCell>(주)청담아이앤씨</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>대표자</TableCell>
                    <TableCell>최용우 외 1명</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>설립일자</TableCell>
                    <TableCell>2020년 1월 20일</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>사업자 등록번호</TableCell>
                    <TableCell>389-86-01681</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>소재지</TableCell>
                    <TableCell>부산광역시 남구 황령대로 319번나길 57, 1층</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>자본금</TableCell>
                    <TableCell>1억원</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>사업종류</TableCell>
                    <TableCell>
                      건물위생관리업, 시설경비업, 소독업, 저수조청소업, 근로자파견업
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: "100%",
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default CompanyOverviewView;
