import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export interface ItemType {
  image: string;
  description: string;
  title: string;
  link: string;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  item: ItemType;
}

const PopupBox = ({ onClose, open, item }: Props): JSX.Element => {
  const { image, description, title } = item;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={"lg"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          overflowY: "hidden",
        },
      }}
    >
      <Grid container spacing={0} position={"absolute"} top={0}>
        <Typography
          variant={"h5"}
          component={Button}
          onClick={onClose}
          marginLeft={"auto"}
        >
          &#9747;
        </Typography>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          <Box
            component={"img"}
            src={image}
            sx={{
              width: 1,
              height: 1,
              objectFit: "cover",
              maxHeight: { xs: 200, sm: 280, md: 1 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingY: { xs: 4, md: 10 },
              paddingX: { xs: 4, md: 12 },
              height: "100%",
            }}
          >
            <Typography
              variant={"h3"}
              fontWeight={700}
              align={"center"}
              marginBottom={5}
            >
              {title}
            </Typography>
            <Typography align={"center"}>{description}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopupBox;
