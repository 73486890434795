import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import { Typography } from "@mui/material";
import { Form } from "./components";

const CareerView = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: "repeat-x",
            position: "relative",
          }}
        >
          <Container>
            <Typography variant={"h2"}>채용문의</Typography>
          </Container>
          <Container>
            <Form />
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: "100%",
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default CareerView;
