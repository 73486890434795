import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Container from "components/Container";

const mock = [
  "/cert1.jpg",
  "/cert2.jpg",
  "/cert3.jpg",
  "/cert4.jpg",
  "/cert5.jpg",
  "/cert6.jpg",
  "/cert7.jpg",
  "/cert8.jpg",
  "/cert9.jpg",
  "/cert10.jpg",
  "/cert11.jpg",
  "/cert12.jpg",
];

const CertificatesLogoGrid = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography gutterBottom align={"center"} variant={"h4"} fontWeight={700}>
            인허가 사항
          </Typography>
          {/*
          <Typography color={"text.secondary"} align={"center"} variant={"h6"}>
            인허가 사항 문구? ex) 청담 I&C는 다음과 같은... 그리고 사회적 기업
            SVG나 배경 투명한 PNG 필요.
          </Typography>
          */}
        </Box>
        <Box display="flex" flexWrap={"wrap"} justifyContent={"center"}>
          {mock.map((item, i) => (
            <Box maxWidth={100} marginTop={2} marginRight={4} key={i}>
              <Box
                component="img"
                height={1}
                width={1}
                src={item}
                alt="..."
                sx={{
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0) invert(0.7)" : "none",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default CertificatesLogoGrid;
