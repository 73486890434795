import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import NavItem from "./components/NavItem";
import { PageNavigationItems } from "layouts/navigation";

interface Props {
  pages: PageNavigationItems;
}

const SidebarNav = ({ pages }: Props): JSX.Element => {
  const {
    introduce: introducePages,
    businesses: businessesPages,
    contact: contactPages,
    portfolio: portfolioPages,
    onlineShop: onlineShopPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Cheongdam I&C"
          width={{ xs: 100, md: 120 }}
        >
          <Box component={"img"} src="/1-1.png" height={1} width={1} />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'회사소개'} items={introducePages} />
        </Box>
        <Box>
          <NavItem title={'사업소개'} items={businessesPages} />
        </Box>
        <Box>
          <NavItem title={'문의'} items={contactPages} />
        </Box>
        <Box>
          <NavItem title={'쇼핑몰'} items={onlineShopPages} />
        </Box>
        <Box>
          <NavItem title={'사업내역'} items={portfolioPages} />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
