import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ItemType, PopupBox } from "./components";
import { Button } from "@mui/material";

const mockLeftGrid = [
  {
    image: "/cert1.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    title: "영업신고증",
  },
  {
    image: "/cert4.jpg",
    description: "Excepteur sint occaecat cupidatat non proident",
    title: "소독업 신고증",
  },
  {
    image: "/cert6.jpg",
    description: "Eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    title: "저수조청소업 신고증명서",
  },
  {
    image: "/cert10.jpg",
    description: "Et harum quidem rerum facilis est et expedita distinctio",
    title: "안전보건 경영시스템 인증서",
  },
];

const mockMiddleGrid = [
  {
    image: "/cert2.jpg",
    description: "At vero eos et accusamus et iusto odio dignissimos ducimus",
    title: "허가증",
  },
  {
    image: "/cert5.jpg",
    description: "Qui blanditiis praesentium voluptatum deleniti atque corrupti",
    title: "장애인기업 확인서",
  },
  {
    image: "/cert7.jpg",
    description: "On the other hand, we denounce with righteous indignation and dislike",
    title: "중소기업 확인서",
  },
  {
    image: "/cert11.jpg",
    description: "Nam libero tempore, cum soluta nobis est eligendi optio",
    title: "품질경영시스템 인증서",
  },
];

const mockRightGrid = [
  {
    image: "/cert3.jpg",
    description: "Et harum quidem rerum facilis est et expedita distinctio",
    title: "사회적기업 인증서",
  },
  {
    image: "/cert6.jpg",
    description: "Nam libero tempore, cum soluta nobis est eligendi optio",
    title: "저수조청소업 신고증명서",
  },
  {
    image: "/cert9.jpg",
    description: "Nam libero tempore, cum soluta nobis est eligendi optio",
    title: "근로자파견사업 허가증",
  },
  {
    image: "/cert12.jpg",
    description: "Nam libero tempore, cum soluta nobis est eligendi optio",
    title: "환경경영시스템 인증서",
  },
];

const Cell = ({ item }: { item: ItemType }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        component={Button}
        sx={{
          marginBottom: { xs: 2, sm: 3 },
          // maxWidth: "33%",
          "&:last-child": { marginBottom: 0 },
        }}
        onClick={() => setOpen(true)}
      >
        <Box
          boxShadow={1}
          sx={{
            position: "relative",
            overflow: "hidden",
            borderRadius: 2,
            "&:hover": {
              "& img": {
                transform: "scale(1.2)",
              },
              "& .portfolio-massonry__main-item": {
                bottom: 0,
              },
            },
          }}
        >
          <Box
            component={"img"}
            loading="lazy"
            height={1}
            width={1}
            src={item.image}
            alt="..."
            maxHeight={{ xs: 400, sm: 600, md: 1 }}
            sx={{
              transition: "transform .7s ease !important",
              transform: "scale(1.0)",
              objectFit: "cover",
              filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            }}
          />
          <Box
            position={"absolute"}
            bottom={"-100%"}
            left={0}
            right={0}
            padding={4}
            bgcolor={"background.paper"}
            className={"portfolio-massonry__main-item"}
            sx={{ transition: "bottom 0.3s ease 0s" }}
          >
            <Box
              component={"svg"}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                transform: "translateY(-90%)",
                zIndex: 2,
                width: 1,
              }}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
            <Typography variant={"h6"} fontWeight={700} gutterBottom>
              {item.title}
            </Typography>
            {/*<Typography>{item.description}</Typography>*/}
          </Box>
        </Box>
      </Box>
      <PopupBox onClose={() => setOpen(false)} open={open} item={item} />
    </>
  );
};

const Column = ({ data }: { data: Array<ItemType> }) => {
  return (
    <Box>
      {data.map((item, i) => (
        <Cell item={item} key={i} />
      ))}
    </Box>
  );
};

const LicenseGrid = (): JSX.Element => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Column data={mockLeftGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockMiddleGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockRightGrid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LicenseGrid;
