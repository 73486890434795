/* eslint-disable react/no-unescaped-entities */
import React from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const mock = [
  {
    title: "시설 경비원",
    subtitle:
      "We design and develop beautiful, lightning fast, scalable, and marketing-focused websites tailored to grow your business.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    title: "시설 미화원",
    subtitle:
      "We craft great user centered interfaces for web and mobile apps. We ensure to deliver intuitive and seamless experiences.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
    ),
  },
  {
    title: "시설유지보수",
    subtitle:
      "We help you to carefully improve your digital product to deliver a great user experience, grow its user base and increase retention.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
        />
      </svg>
    ),
  },
  {
    title: "주차장경비",
    subtitle:
      "We transform businesses into brands through high quality brand identity design.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    ),
  },
];

const Features = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant={"h6"}
          component={"p"}
          color={"text.secondary"}
          align={"center"}
        >
          경비 / 미화에 관한 세부적 설명 : 건물을 사용하는 방문객 및 사용자를
          위험으로부터 보호하고 건물 내에 발생할 수 있는 각종 사고나 범죄 등
          위험을 방지하는 유인경비 형태의 경비시스템을 제공합니다. 세부 경비
          업무로는 주/야간 출입자 통제, 방문차량 관리 및 통제, 안전 수시 점검 및
          순찰, 안전사고 예방, 시설물 보안, 내방객 안내 등이 있으며, 아파트 및
          오피스텔 관공서, 공장등에서 주로 이용하고 있습니다.
          <br />
          미화분야에서는 건물의 각 특성에 맞게 원하는 시간대, 장소에 따라
          미화원을 통하여 합리적인 비용으로 청결한 위생서비스를 제공합니다. 세부
          업무사항으로는 건물 내,외부청소, 건물 청결유지 및 환경보전, 주차장
          청소 및 소독등이 있으며, 공동주택 및 다세대, 마트, 상가, 복합상가,
          사무실 및 공공기관 등에서 주로 이용하고 있습니다.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={3} key={i}>
              <Box
                width={1}
                height={1}
                data-aos={"fade-up"}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box
                    component={Avatar}
                    width={50}
                    height={50}
                    marginBottom={2}
                    bgcolor={theme.palette.primary.main}
                    color={theme.palette.background.paper}
                  >
                    {item.icon}
                  </Box>
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>
                  {/*
                  <Typography color="text.secondary">
                    {item.subtitle}
                  </Typography>
                  */}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Features;
