/* eslint-disable react/no-unescaped-entities */
import React, {useState} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {useTheme} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const mock = [
  {
    title: 2020,
    subtitle: "2020년도 설립",
    suffix: "",
  },
  {
    title: 134,
    subtitle: "시공사업 134건 이상",
    suffix: "+",
  },
  {
    title: 13,
    subtitle: "13개 이상의 제휴업체",
    suffix: "+",
  },
  {
    title: 13,
    subtitle: "시공 경력 13년",
    suffix: "",
  },
];

const Features = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? "row" : "column-reverse"}>
        <Grid item xs={12} md={6} data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={4}>
            <Typography sx={{ fontWeight: 700 }} variant={"h4"} gutterBottom>
              소개
            </Typography>
            <Typography variant={"h6"} component={"p"} color={"text.secondary"}>
              (주)청담아이앤씨는 2012년 설립된 (주)청담산업을모체로 변화하는
              시장 구조에 발맞춰 새롭게 시작하기 위해 설립된 경비·용역·청소
              전문업체입니다.
              <br/>
              관련 분야 10년 이상 경력의 전문가들과 창업정신으로 뭉친 젊은 인재들을 영입함으로써 전문성과 혁신성을 갖춘 기업으로 만들어 내었습니다.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={3} md={3}>
                <Typography variant="h4" color={"primary"} gutterBottom>
                  <VisibilitySensor
                    onChange={(isVisible) => setViewPortVisibility(isVisible)}
                    delayedCall
                  >
                    <CountUp
                      duration={2}
                      end={viewPortEntered ? item.title : 0}
                      start={0}
                      suffix={item.suffix}
                    />
                  </VisibilitySensor>
                </Typography>
                <Typography color="text.secondary" component="p">
                  {item.subtitle}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={300}
              image="/1-5.jpg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
