import { BrowserRouter, Route, Routes } from "react-router-dom";
import IndexView from "pages/IndexView";
import IntroduceView from "pages/IntroduceView";
import CompanyOverviewView from "pages/CompanyOverviewView";
import CompanyLocationView from "pages/CompanyLocationView";
import CompanyLicenseView from "pages/CompanyLicenseView";
import CompanyAttachmentsView from "pages/CompanyAttachmentsView";
import ContactView from "pages/ContactView";
import CareerView from "pages/CareerView";
import PortfolioView from "pages/PortfolioView";
import BusinessCleaningView from "pages/BusinessCleaningView";
import BusinessSecurityView from "pages/BusinessSecurityView";
import BusinessEquipmentView from "pages/BusinessEquipmentView";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexView />} />
      <Route path="/about/introduce" element={<IntroduceView />} />
      <Route path="/about/overview" element={<CompanyOverviewView />} />
      <Route path="/about/location" element={<CompanyLocationView />} />
      <Route path="/about/license" element={<CompanyLicenseView />} />
      <Route path="/about/download" element={<CompanyAttachmentsView />} />
      <Route path="/contact" element={<ContactView />} />
      <Route path="/career" element={<CareerView />} />
      <Route path="/portfolio" element={<PortfolioView />} />
      <Route path="/solutions/cleaning" element={<BusinessCleaningView />} />
      <Route path="/solutions/security" element={<BusinessSecurityView />} />
      <Route path="/solutions/equipment" element={<BusinessEquipmentView />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
