/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "올바른 이름을 입력해주세요.")
    .max(50, "올바른 이름을 입력해주세요.")
    .required("이름은 필수입니다."),
  lastName: yup
    .string()
    .trim()
    .min(1, "올바른 이름을 입력해주세요.")
    .max(50, "올바른 이름을 입력해주세요.")
    .required("성은 필수입니다."),
  email: yup
    .string()
    .trim()
    .email("올바른 이메일 주소를 입력해주세요.")
    .required("이메일은 필수입니다."),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      "올바른 전화번호를 입력해주세요."
    ),
  address: yup.string().trim().max(100, "주소는 100자를 초과할 수 없습니다."),
  message: yup.string().trim().max(500, "내용은 500자를 초과할 수 없습니다."),
  agreement: yup.boolean().oneOf([true], "필수 항목입니다."),
});

const Form = (): JSX.Element => {
  const form = useRef(null);
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    agreement: false,
  };

  const onSubmit = (values) => {
    const emailConfig = {
      serviceId: "service_nbpirz5",
      recruitTemplateId: "template_zc2d06v",
      publicKey: "M4KLm1SeiSVYRV-Hv",
    };

    emailjs
      .sendForm(
        emailConfig.serviceId,
        emailConfig.recruitTemplateId,
        form.current,
        emailConfig.publicKey
      )
      .then(
        (res) => {
          alert("채용 문의 요청이 완료 되었습니다.");
          navigate("/");
        },
        (err) => {
          alert("채용 문의 요청에 실패하였습니다. 다시 시도해주세요.");
          console.log(err);
        }
      );

    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <form ref={form} onSubmit={formik.handleSubmit}>
        <Box component={Grid} marginBottom={{ xs: 10, sm: 0 }} container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Required *
            </Typography>
            <TextField
              label="이름"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              // @ts-ignore
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Required *
            </Typography>
            <TextField
              label="성"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              // @ts-ignore
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Required *
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              // @ts-ignore
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="전화번호"
              variant="outlined"
              name={"phone"}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              // @ts-ignore
              helperText={formik.touched.phone && formik.errors.phone}
              sx={{
                mt: 4.6,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="주소"
              variant="outlined"
              name={"address"}
              fullWidth
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              // @ts-ignore
              helperText={formik.touched.address && formik.errors.address}
              sx={{
                mt: 4.6,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="자기소개"
              variant="outlined"
              name={"message"}
              fullWidth
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              // @ts-ignore
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <FormControlLabel
                control={<Checkbox checked={formik.values.agreement} />}
                label="개인정보의 수집·이용에 관한 동의"
                name={"agreement"}
                onChange={formik.handleChange}
              />
              <FormHelperText
                error={formik.touched.agreement && Boolean(formik.errors.agreement)}
              >
                <>{formik.touched.agreement && formik.errors.agreement}</>
              </FormHelperText>
              <Button
                sx={{
                  ml: 2,
                  width: 100,
                }}
                variant={"outlined"}
              >
                상세 보기
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Button size={"large"} variant={"contained"} type={"submit"}>
              요청 전송
            </Button>
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              sx={{ marginTop: 2 }}
              align={"center"}
            >
              영업일 기준 1-2일 이상 소요될 수 있습니다.
            </Typography>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
