import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export interface ItemType {
  image: string;
  description: string;
  title: string;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  item: ItemType;
}

const PopupBox = ({ onClose, open, item }: Props): JSX.Element => {
  const { image } = item;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={"lg"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
        },
      }}
    >
      <Grid container spacing={0} position={"absolute"} top={0}>
        <Typography
          variant={"h5"}
          component={Button}
          onClick={onClose}
          marginLeft={"auto"}
        >
          &#9747;
        </Typography>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            component={"img"}
            src={image}
            sx={{
              width: 1,
              height: 1,
              objectFit: "cover",
              maxHeight: { xs: 200, sm: 280, md: 1 },
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopupBox;
