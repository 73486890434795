import React from "react";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import Container from "components/Container";

const mock = {
  image: "/1-2.4.jpg",
  description: (
    <>
      저희 “㈜청담아이앤씨”는 건물위생/방역/시설경비 관리전문업체로서 관리,
      청소, 전문직 등을 사원으로 채용하여 적기에 사용업체에 파견, 각 기업 문제에
      대하여 도움을 드리고 있으며, 사용사업주에 대한 최고의 서비스와 정성으로
      취업과 고용안정에 관한 문제를 해결 함으로써, 든든한 동반자가 되어
      드리겠습니다.
      <br />
      기업 노하우와 경쟁력은 인적자원에서 나오며, 그 중요함은 더욱 커져갈 것
      입니다. 현재의 방식으론 남들과 같은 한계에 머물며 경쟁자와의 차별성 없이는
      그 이상의 능력을 발휘 할 수 없을 것입니다. 보다 전문적인 관리를 통한
      기업의 운영만이 기업 경쟁력을 높이는 요소가 될 것입니다.
      <br />
      아웃소싱을 활용을 통하여 고비용 문제의 절감과, 단순하고 반복적인 업무 등이
      외부화됨에 따라 기업내의 조직이 간편화 되고, 운영이 편리한 고용형태를
      만들어 경영자원의 집중과 품질 향상의 우수성을 보여 드리겠습니다. 더하여
      용역분야에서 사회적 경제를 이끌어가는 선도기업으로 지역 무료 방역 및
      취약계층 고용을 확대해 나가고 있습니다. 귀사 또한 저희
      “㈜청담아이앤씨”와의 단순 협업만으로도 지역 경제에 보탬이 되는 지역경제
      상생 기업으로서의 저변을 넓힐 수 있음을 자신있게 말씀드립니다.
    </>
  ),
  title: "모두를 만족시키는 건물관리 전문기업, 청담아이앤씨가 시작하겠습니다.",
  author: {
    name: "대표이사 최용우",
    avatar: "/author.jpg",
  },
  date: "",
};

const IntroduceMessageCard = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Container>
      <Box
        display={"block"}
        width={1}
        height={1}
        sx={{
          textDecoration: "none",
          transition: "all .2s ease-in-out",
          "&:hover": {
            transform: `translateY(-${theme.spacing(1 / 2)})`,
          },
        }}
      >
        <Box
          component={Card}
          width={1}
          height={1}
          boxShadow={4}
          display={"flex"}
          flexDirection={{ xs: "column", md: "row-reverse" }}
          sx={{ backgroundImage: "none" }}
        >
          <Box
            sx={{
              width: { xs: 1, md: "50%" },
              position: "relative",
              display: "flex",
              // justifyContent:'center',
              // padding:4,
            }}
          >
            <Box
              component={"img"}
              height={1}
              width={1}
              src={mock.image}
              alt="..."
              sx={{
                objectFit: "cover",
                maxHeight: "100%",
                
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              }}
            />
            {/*<Chip
              label="Featured"
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
                bgcolor: 'background.paper',
              }}
            />*/}
            <Box
              component={"svg"}
              viewBox="0 0 112 690"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              sx={{
                position: "absolute",
                bottom: 0,
                top: "-50%",
                left: 0,
                right: 0,
                color: theme.palette.background.paper,
                transform: "scale(2)",
                height: 1,
                width: "auto",
                transformOrigin: "top center",
                display: { xs: "none", md: "block" },
              }}
            >
              <path
                d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
                fill="currentColor"
              />
            </Box>
          </Box>
          <CardContent
            sx={{
              position: "relative",
              width: { xs: 1, md: "50%" },
              padding: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant={"h5"} gutterBottom>
                {mock.title}
              </Typography>
              <Typography color="text.secondary">{mock.description}</Typography>
            </Box>
            <Box>
              <Divider sx={{ marginY: 2 }} />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Avatar src={mock.author.avatar} sx={{ marginRight: 1 }} />
                  <Typography color={"text.secondary"}>
                    {mock.author.name}
                  </Typography>
                </Box>
                <Typography color={"text.secondary"}>{mock.date}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Container>
  );
};

export default IntroduceMessageCard;
