import React from "react";
import Router from "./pages/Router";
import Page from "components/Page";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function App() {
  return (
    <Page>
      <Router />
    </Page>
  );
}

export default App;
