import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const images = [
  {
    group: [
      {
        cover: "/clean3.jpg",
        coverDark: "/clean3.jpg",
      },
      {
        cover: "/clean4.jpg",
        coverDark: "/clean4.jpg",
      },
    ],
  },
  {
    group: [
      {
        cover: "/clean1.jpg",
        coverDark: "/clean1.jpg",
      },
      {
        cover: "/clean2.jpg",
        coverDark: "/clean2.jpg",
      },
    ],
  },
  {
    group: [
      {
        cover: "/cert9.jpg",
        coverDark: "/cert9.jpg",
      },
      {
        cover: "/cert5.jpg",
        coverDark: "/cert5.jpg",
      },
      {
        cover: "/cert2.jpg",
        coverDark: "/cert2.jpg",
      },
      {
        cover: "/cert8.jpg",
        coverDark: "/cert8.jpg",
      },
    ],
  },
];

const Hero = (): JSX.Element => {
  const theme = useTheme();
  /*
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  */

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: '50%' }} marginTop={{ xs: '71px' }}>
            <Typography
              variant="h2"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              청담 I&C를
              <br />
              소개합니다.
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              모두를 만족시키는 건물관리 전문기업!
              <br />
              청담아이앤씨가 시작하겠습니다!
            </Typography>
            <Container maxWidth={{ xs: 1 }}>
              <Box
                component={'img'}
                src="/cert_hero_1.png"
                width={0.3}
                height={0.3}
                marginRight={5}
              />
              <Box component={'img'} src="/cert_hero_2.png" width={0.3} height={0.3} />
            </Container>
          </Box>
        </Container>
        <Box
          sx={{
            transform: 'rotate(-20deg)',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box
            display={'flex'}
            width={'50rem'}
            left={'50%'}
            top={0}
            position={'absolute'}
            sx={{ transform: 'translate3d(20%, -50%, 0)' }}
          >
            {images.map((item, i) => (
              <Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                {item.group.map((g, j) => (
                  <Box
                    key={j}
                    padding={1}
                    bgcolor={'background.paper'}
                    borderRadius={2}
                    boxShadow={3}
                    marginTop={2}
                  >
                    <Box
                      component={'img'}
                      loading="lazy"
                      src={theme.palette.mode === 'dark' ? g.coverDark : g.cover}
                      height={1}
                      width={1}
                      maxWidth={320}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
