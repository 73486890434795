import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Work = (): JSX.Element => {
  //const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
        >
          장비 / 용품에 관한 세부적 설명 : 일반시중에서 보지 못하는
          청소용세제부터 왁스, 오븐크리너, 박리제등 다양한 세제들과
          화장실청소용품, 위생용품, 청소장비들을 취급하고 있습니다. 세부
          청소용품으로는 철수세미, 밀대, 밀대걸레, 막대걸레, 걸레등이 있고
          청소장비로는 업소용청소기, 산업용청소기, 마루광택기, 탑승식청소차등이
          있습니다. 저희 ㈜청담아이엔씨에서는 고객님의 요청에 의해 정기적으로
          청소용품납품 및 위생용품납품을 실시하고 있으며, 청소의 효율을
          높이기위해 기타 청소장비도 같이 공급하고 있습니다.
        </Typography>
        <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          <Button
            color={"primary"}
            variant={"contained"}
            size={"large"}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width={20}
                height={20}
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            }
            component={"a"}
            target={"_blank"}
            href={"https://smartstore.naver.com/cheongdamcleanmall"}
          >
            쇼핑몰로 가기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Work;
