export interface PageNavigationItems {
  introduce: Array<PageItem> | PageItem;
  businesses: Array<PageItem> | PageItem;
  contact: Array<PageItem> | PageItem;
  portfolio: Array<PageItem> | PageItem;
  onlineShop: Array<PageItem> | PageItem;
}

export function isArray<T>(arg: any): arg is Array<T> {
  return Array.isArray(arg);
}

const pages: PageNavigationItems = {
  introduce: [
    {
      title: "인사말",
      href: "/about/introduce",
    },
    {
      title: "개요",
      href: "/about/overview",
    },
    {
      title: "오시는길",
      href: "/about/location",
    },
    {
      title: "인허가 사항",
      href: "/about/license",
    },
    {
      title: "자료실",
      href: "/about/download",
    },
  ],
  businesses: [
    {
      title: "청소/방역",
      href: "/solutions/cleaning",
    },
    {
      title: "경비/미화",
      href: "/solutions/security",
    },
    {
      title: "장비/용품",
      href: "/solutions/equipment",
    },
  ],
  contact: [
    {
      title: "견적요청",
      href: "/contact",
    },
    {
      title: "채용문의",
      href: "/career",
    },
  ],
  portfolio: {
    title: "시공내역",
    href: "/portfolio",
    // href: "https://blog.naver.com/chd5567",
  },
  onlineShop: {
    title: "쇼핑몰",
    href: "https://smartstore.naver.com/cheongdamcleanmall",
  },
};

export default pages;
